import { useContext } from "react";
import ChatBot from "./ChatBot";
import NavBar from "./Nav";
import { DataContext } from "../Context/DataProvider";
import "./MainPage.css"


function MainPage() {
    const { mode } = useContext(DataContext)
    return (
        <>
            <div className={mode}>
                <div className={`sticky top-0 z-50`}>
                    <NavBar />
                </div>
                <div className="min-h-screen -mt-[3rem] sm:-mt-[5.75rem] bg-gradient-to-br bg-white dark:bg-[#242529] sm:p-4 md:p-6 lg:p-8 vertical-pos">
                    <ChatBot />
                </div>
            </div>
        </>
    );
}

export default MainPage;