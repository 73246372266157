import React, { useContext } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { DataContext } from '../Context/DataProvider';
import './Nav.css';


const Nav = () => {
    const { mode, setMode } = useContext(DataContext);

    // const handleModeToggle = (e) => {
    //     if (e === 'dark') {
    //         setMode('light');
    //     }
    //     else {
    //         setMode('dark')
    //     }
    // }
    return (
        <div className='nav bg-white dark:bg-[#242529] flex items-center justify-between h-[3.75rem] md:h-[4.75rem] lg:h-[5.75rem]'>
            {
                    <>
                        <img className=' h-[3.75rem] md:h-[4.75rem] lg:h-[5.75rem] py-2 logo-align' src="white-logo.png" alt="terrveal" />
                    </>
                    
            }</div>
    );
};

export default Nav;