import React from 'react';

const Intro = () => {
    return (
        <div className='h-full w-full flex items-center justify-center text-center'>
            <p className='text-2xl sm:text-3xl md:text-4xl text-[#333333] dark:text-white'>Hi, how can I help?</p>            
        </div>
    );
};

export default Intro;