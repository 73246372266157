import React from 'react';
import MainPage from './components/MainPage';
import { DataProvider } from './Context/DataProvider';

function App() {
  return (
    <>
      <DataProvider>
        <MainPage />
      </DataProvider>
    </>
  );
}

export default App;