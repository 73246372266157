import React, { useState, useRef, useEffect } from 'react';
import { Send, Trash2, Loader2 } from 'lucide-react';
import { Card, CardContent, CardFooter } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import { motion } from 'framer-motion';
import Intro from './ui/Intro';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [intro, setIntro] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messageEndRef = useRef(null);




  const handleIntro = (e) => {
    setIntro(e)
  }

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatBotResponse = (text) => {
    const paragraphs = text.split('\n\n');

    return paragraphs.map((paragraph, index) => {
      if (paragraph.includes('1.')) {
        const listItems = paragraph.split(/\d+\.\s/).filter(item => item.trim() !== '');
        return (
          <div key={index} className="mb-2">
            <ul className="list-decimal list-inside">
              {listItems.map((item, i) => (
                <li key={i} className="mb-1">{item.trim()}</li>
              ))}
            </ul>
          </div>
        );
      } else {
        return <p key={index} className="mb-2">{paragraph}</p>;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = { text: input, isUser: true };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);
    setError(null);

    try {
      
      let back_api = `http://146.190.113.210:8000/api/result?question=${encodeURIComponent(input)}`;

      const response = await fetch(back_api);
      if (!response.ok) {
        throw new Error('Failed to get response from the server');
      }
      const data = await response.json();

      const botMessage = { text: data.answer || 'Sorry, I couldn\'t process that.', isUser: false };
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setError('An error occurred while fetching the response. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    setIntro('')
    setMessages([]);
    setError(null);
  };

  return (
    <>
      <div className={`flex items-start justify-center`}>
        <Card className=" w-full max-w-2xl xl:max-w-3xl mx-auto border-none bg-gradient-to-br">
          {
            intro === '' ? <div className="min-h-[80vh] flex items-center  border-none p-6 space-y-4 pt-8">
              <Intro />
            </div>
              :
              <CardContent className="min-h-[80vh] border-none p-6 space-y-4 pt-[5.75rem]">
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}
                  >
                    <div className={`max-w-[80%] p-3 rounded-lg ${message.isUser
                      ? ' bg-[#F4F4F4] dark:bg-[#197270] text-black dark:text-[#FFF]'
                      : 'dark:bg-[#2e2f34] text-black dark:text-[#aeafb3]'
                      }`}>
                      {message.isUser ? (
                        <p className='text-wrap'>{message.text}</p>
                      ) : (
                        <div className="prose prose-sm">
                          {formatBotResponse(message.text)}
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
                {isLoading && (
                  <div className="flex justify-center items-center">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
                  </div>
                )}
                {error && (
                  <Alert variant="destructive" className="mt-4 text-white bg-[#2e2f34]">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
                <div ref={messageEndRef} />
              </CardContent>
          }
          <CardFooter className=" bg-transparent border-none pt-6">
            <form onSubmit={handleSubmit} className="w-full flex items-center space-x-1 sm:space-x-2 ">
              <Input
                type="text"
                value={input}
                autoFocus
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type message..."
                className="rounded-full"
                disabled={isLoading}
              />
              <Button
                type="submit"
                disabled={isLoading || !input.trim()}
                onClick={() => handleIntro(input)}
                className="from-[#ea23fc] to-[#4d07ff] bg-gradient-to-br text-white h-10 sm:h-12 w-10 sm:w-12 rounded-full"
              >
                <Send className="h-4 w-4 " />
              </Button>
              <Button
                type="button"
                onClick={clearChat}
                className={` bg-red-500 hover:bg-red-700 text-white transition-colors duration-100 h-10 sm:h-12 w-10 sm:w-12 rounded-full ${intro === '' ? 'hidden' : ''} `}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </form>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default ChatBot;