import { createContext, useState } from 'react';
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [mode, setMode] = useState("dark");
    return (
        <DataContext.Provider value={{mode, setMode }}>
            {children}
        </DataContext.Provider>
    );
};
