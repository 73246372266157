import * as React from "react"

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      autoFocus
      className={`flex items-center h-10 sm:h-12 bg-background px-3 py-2 text-sm ring-offset-background flex-grow sm:pl-5 bg-transparent dark:bg-[#2d2e33] input input-bordered w-full border dark:border-none outline-none text-black dark:text-white tracking-custom flex-1  disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = "Input"

export { Input }